<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Income Proof</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-11 white_box p-4">
                <form [formGroup]="model" novalidate autocomplete="off" (ngSubmit)="submit()">
                <div class="row justify-content-center">
                    <div class="col-sm-10 col-12">
                        <ng-container @fadeInOut>
                            <div class="row">
                                <div class="col-12" @fadeInOut>
                                    <div class="form-group">
                                        <div class="mt-3">
                                            <label class="control-label">Income Proof Document </label><span style="font-family: 'Roboto', sans-serif;font-size: 0.8rem;/* color: #044b5f; *//* font-weight: 600; */margin-bottom: 0px !important;"> (ITR)<span style="color:red">*</span></span>
                                            <input #incomedoc class="form-control" type="file" accept=".pdf" id="businessProof" (change)="onFileSelected($event)" text="Choose File" style="margin-top: .8rem"/>
                                        </div>
                                    </div>
                                  </div> 
                            </div>
                            
                            <div class="row mt-4" @fadeInOut>
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn btn-warning" [attr.disabled] = "!fileSelected ? true : null" >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-5 col-11">
    <app-assist-left></app-assist-left>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>