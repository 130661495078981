<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-4">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Current Address Proof</h3>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-11 white_box p-4">
                <form [formGroup]="model" novalidate autocomplete="off" (ngSubmit)="submit()">
                <div class="row justify-content-center">
                    <div class="col-sm-10 col-12">
                        <ng-container>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label class="control-label">Residence type</label>
                                        <select formControlName="residenceType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please residence type" selected disabled>Please residence type</option>
                                            <option *ngFor="let type of residenceTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                        <!-- <span *ngIf="model.get('companyType')?.invalid && model.get('companyType')?.touched" class="alert alert-danger mt-2">
                        Company Type is mandatory
                      </span> -->
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="model.get('residenceType')?.value == 'Owned'" @fadeInOut>
                                    <div class="form-group">
                                        <label class="control-label">Document Type</label>
                                        <select formControlName="documentType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please document type" selected disabled>Please document type</option>
                                            <option *ngFor="let type of documentOwnedTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="model.get('residenceType')?.value == 'Rented'" @fadeInOut>
                                    <div class="form-group">
                                        <label class="control-label">Document Type</label>
                                        <select formControlName="documentType" class="form-select"
                                            style="margin-top: .3rem;">
                                            <option value="Please document type" selected disabled>Please document type</option>
                                            <option *ngFor="let type of documentRentedTypes" [value] = "type">{{
                                                type }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="documentRentedTypes.includes(model.get('documentType')?.value) || documentOwnedTypes.includes(model.get('documentType')?.value)" @fadeInOut>
                                    <div class="form-group">
                                        <div class="mt-3">
                                          <label class="control-label">{{model.get('documentType')?.value}} </label><span style="color:red">*</span>
                                          <input #utilitydoc class="form-control" type="file" accept=".pdf, .jpg, .jpeg, .png" id="currentAddressProod" (change)="onFileSelected($event)" text="Choose File" style="margin-top: .8rem"/>
                                        </div>
                                    </div>
                                  </div> 
                            </div>
                            
                            <div class="row mt-4" @fadeInOut>
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn btn-warning" [attr.disabled] = "!fileSelected ? true : null" >
                                        Upload
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="col-lg-5 col-11">
    <app-assist-left></app-assist-left>
</div>
<ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>