import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom, Subscription, switchMap, timer } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import { User } from '../../../core/models/user';
import { SessionTimerService } from '../../../core/services/session-timer.service';
import swal from 'sweetalert2';

export interface iConfirmDetails {
  confirmPersonalDetails: boolean;
  navigateToMihuruUrlForDocsUpload: boolean;
  partnerCallbackUrl: string;
  panid: string;
  pincode: string;
  dob: string;
  dobui: string;
  gender: string;
  iConfirm: boolean;
}
@Component({
  selector: 'app-assist-confirm-personal-details',
  templateUrl: './assist-confirm-personal-details.component.html',
  styleUrls: ['./assist-confirm-personal-details.component.scss'],
})
export class AssistConfirmPersonalDetailsComponent implements OnInit {
  @ViewChild('form', { read: NgForm }) form!: NgForm;
  formReq: iConfirmDetails = {
    confirmPersonalDetails: true,
    navigateToMihuruUrlForDocsUpload: true,
    partnerCallbackUrl: 'www.mihuru.com',
    panid: '',
    pincode: '',
    dob: '',
    dobui: 'dd-MMM-yyyy',
    gender: '',
    iConfirm: false,
  };
  minDate: string;
  maxDate: string;
  showConfirmCheckbox: boolean;
  isCreditMobileNoError: boolean =  false;
  creditMobileNo: string = '';
  CreditUpdate: FormGroup;
  CreditUpdateConfirm: FormGroup;
  creditPopUpErrorMessage: string = '';
  genderFetched: boolean =  false;
  panFetched: boolean = false;
  pincodeFetched: boolean = false;
  dobFetched: boolean = false;
  showMobileNo: boolean = false;
  resendAvailable: boolean = false;
  timeRemaining: number = 60;
  private timerSubscription: Subscription | undefined;
  private timerKey: string = 'otpTimer';
  age: number;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionTimerService :SessionTimerService,
    private datePipe: DatePipe
  ) {
    // const today = new Date();
    // const minYear = today.getFullYear() - 58;
    // const maxYear = today.getFullYear() - 18;
    // // Set minDate to 58 years ago and maxDate to 18 years ago from today
    // this.minDate = new Date(minYear, today.getMonth(), today.getDate())
    //   .toISOString()
    //   .split('T')[0];
    // this.maxDate = new Date(maxYear, today.getMonth(), today.getDate())
    //   .toISOString()
    //   .split('T')[0];
    this.CreditUpdate = new FormGroup({
      mobileNo: new FormControl('')
    });

    this.CreditUpdateConfirm = new FormGroup({
      mobileOTP: new FormControl('')
    })

  }

  ngOnInit(): void {
    const pan = MemoryStorageService.getItem('pan');
    if(pan && pan != '' && pan !== 'undefined'){
      this.formReq.panid = pan;
      this.panFetched = true;
    }
    const pincode = MemoryStorageService.getItem('pincode');
    if(pincode && pincode != '' && pincode !== 'undefined'){
      this.formReq.pincode = pincode;
      this.pincodeFetched = true;
    }
    const dob = MemoryStorageService.getItem('dob');
    if(dob && dob != '' && dob !== 'undefined'){
      this.formReq.dob = dob;
      this.formReq.dobui = this.datePipe.transform(this.formReq.dob, 'dd-MMM-yyyy');
      this.dobFetched = true;
    }
    const gender = MemoryStorageService.getItem('gender');
    if(gender && gender != '' && gender !== 'undefined'){
      this.formReq.gender = gender;
      this.genderFetched = true;
    }
    this.showConfirmCheckbox = JSON.parse(MemoryStorageService.getItem('iConfirm'));
    this.calculateAge();

    //Session Timer
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();
    MemoryStorageService.removeItem('timerEndTime');

    //For OTP Timer
    this.loadTimer();
    this.startTimer();

  }

  async submit() {
    this.spinner.show();

    let generateTravelPlansReq: any = {
      confirmPersonalDetails: true,
      navigateToMihuruUrlForDocsUpload: true,
      partnerCallbackUrl: '',
      panid: this.formReq.panid,
      pincode: this.formReq.pincode,
      dob: this.formReq.dob,
      gender: this.formReq.gender,
    };

    try{
      const response = await firstValueFrom(this.accountService.generateTravelPlans(
          generateTravelPlansReq,
          MemoryStorageService.getItem('customerToken')
      ));
      
      console.log(response);

      if(response['error'] && response['error'].hasOwnProperty('errors') && response['errors'].errors.includes('Please provide mobile ending with')){
        this.openCreditMobileUpdatePopUp(response['error'].errors);
      }

      if(response['data']==null)
        {
          this.spinner.hide();
          this.router.navigateByUrl('/assist-journey/sorry');
        }
      MemoryStorageService.setItem(
        'assistTravelPlans',
        JSON.stringify(response['data']['plan_details'])
      );
      

      //

      if(this.checkPlanEligibility(response['data']['plan_details'])){
        return this.router.navigateByUrl('/assist-journey/assist-underwrite');
      }
      

      //starting the Session Timer
      this.sessionTimerService.SetShowTimer(true);
      this.sessionTimerService.startTimer();

      // Setting SessionId to be used on Session Expire Page for updating the apllication Status 
      const user: User = { token:MemoryStorageService.getItem('customerToken'), userName: '', email: '', mobileNumber: ''}
      this.accountService.setCurrentUser(user);
      //////

      //Setting if Available Limit has exhausted 
      // const travelcost = parseInt(MemoryStorageService.getItem('TravelCost'));
      // const availableLimit = parseInt(response['data']['availableLimit']);

      //prajakta removed it, since we are checking this on api side, if available limit < travel cost then no plans will be generated
      //MemoryStorageService.setItem('hasLimitExhausted', travelcost > availableLimit ? "true": "false")
      /////

       /*** skip the plan page with selecting max tenure for all landing page users (TaskId 1073) ***/
       if(MemoryStorageService.getItem("userSource") === 'LANDING_PAGE'){
        const maxTenorPlan = response['data'].plan_details.reduce((max, plan) => {
          return (plan.tenor > max.tenor) ? plan : max;
        });
      
        return this.router.navigateByUrl(
          '/assist-journey/'+ maxTenorPlan.navigate_url.split('/')[4]
        );
      }
      /********************/

      this.spinner.hide();
      this.router.navigateByUrl('/assist-journey/payment-plans', {
        state: {
          assistTravelPlans: JSON.stringify(
            response['data']['plan_details']
          ),
        },
      });
  
      this.spinner.hide();
    }
    catch(error1){
      this.spinner.hide();
      if(error1['error'] && error1['error'].hasOwnProperty('errors') && error1['error'].errors.includes('Please provide mobile ending with')){
        this.openCreditMobileUpdatePopUp(error1['error'].errors);
      }
    }
  }

  checkPlanEligibility(plans: any[]) {
    const hasEligiblePlan = plans.some(plan => plan.plan_eligibility);
    return !hasEligiblePlan
  }

  capitalizeInput(fieldName: string) {
    this.formReq[fieldName] = this.formReq[fieldName].toUpperCase();
  }

  openCreditMobileUpdatePopUp(message: string){

    try
    {
      this.creditPopUpErrorMessage = message;
      this.creditMobileNo = message.slice(-5); 
    }
    catch(err){}

    document.getElementById('overlay-credit-phone')!.style.display = 'block';
    document.getElementById('popup-credit-phone')!.style.display = 'block';
    this.showMobileNo = true;
  }

  calculateAge() {
    if (this.formReq.dob) {
      const birthDate = new Date(this.formReq.dob);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      this.age = age;
    }
  }
  maskValue(value: string, repeatX: number, showChar: number): string {
    if (value.length > repeatX) {
      const firstTwoChars = value.substring(0, showChar);
      const lastTwoChars = value.substring(
        value.length - showChar,
        value.length
      );
      const maskedChars = 'X'.repeat(repeatX);
      return `${firstTwoChars}${maskedChars}${lastTwoChars}`;
    }
    return value; // Return the value as is if it's 4 characters or less
  }
  allowOnlyNumbers(event: KeyboardEvent) {
    const char = event.key;
    // Allow only numeric input
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }


  async SendAlternativeOTP(mobileNo: string){
    try{
      this.spinner.show();
      //const token = MemoryStorageService.getItem('customerToken');
      MemoryStorageService.setItem("AlternateMobileNo", mobileNo)
      const res = await firstValueFrom(this.accountService.GetOTPForAlternativeNumber(mobileNo));
      if(res['error'] == null && res['data'] == 'OTP Sent successfully on given mobile number.'){
        swal.fire("OTP Sent successfully");
        this.showMobileNo = false;
        this.spinner.hide();
        return;
      }
      swal.fire("Could not send OTP! Please try again.");
    }
    catch(err){
      console.log(err);
    }
    this.spinner.hide();
  }

  async ConfirmNewNumber(otp: string){
    try{
      this.spinner.show();
      const token = MemoryStorageService.getItem('customerToken');
      const mobileNo = MemoryStorageService.getItem("AlternateMobileNo")
      const res = await firstValueFrom(this.accountService.UpdateAlternativeNumberForCredit(mobileNo, otp, token));
      if(res['error'] == null){
        swal.fire("Validation successful");
        this.spinner.hide();
        await this.submit();
        return;
      }
      else if (res['error'] != null && res['error'].response_summary == 'OTP provided is incorrect')
      {
        swal.fire("OTP provided is incorrect");
      }
      else if(res['error'] != null && res['error'].response_msg == 'Wrong OTP entered thrice. Please generate new OTP.'){
        swal.fire("Wrong OTP entered thrice, Please generate new OTP!");
      }
      else{
        swal.fire("Unable to update mobile number");
      }
    }
    catch(err){
      console.log(err);
    }
    this.spinner.hide();
  }


  formatDate(event: any): void {
    const selectedDate = event.target.value;
    const formattedDateDOB = this.datePipe.transform(selectedDate, 'dd-MMM-yyyy');
    this.formReq.dob = selectedDate; 
    this.formReq.dobui = formattedDateDOB; 
  }

  // InputDob(event: any): void 
  // {
    
  //   const selectedDate = event.target.value;
  //   const formattedDateDOB = this.datePipe.transform(selectedDate, 'yyyy-MM-dd');
  //   this.formReq.dob = formattedDateDOB; 
  // }

  
  /* OTP Timer */
  startTimer(): void {
    const now = new Date().getTime();
    const savedExpiryTime = MemoryStorageService.getItem(this.timerKey);
    if (savedExpiryTime) {
      const expiryTime = parseInt(savedExpiryTime, 10);
      this.timeRemaining = Math.max(Math.floor((expiryTime - now) / 1000), 0);
      this.resendAvailable = this.timeRemaining === 0;
    }
    this.timerSubscription = timer(0, 1000).pipe(
      switchMap(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
          this.saveTimer();
          return [this.timeRemaining];
        } else {
          this.resendAvailable = true;
          MemoryStorageService.removeItem(this.timerKey);
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          return [];
        }
      })
    ).subscribe();
  }

  saveTimer(): void {
    const expiryTime = new Date().getTime() + (this.timeRemaining * 1000);
    MemoryStorageService.setItem(this.timerKey, expiryTime.toString());
  }

  loadTimer(): void {
    const savedExpiryTime = MemoryStorageService.getItem(this.timerKey);
    if (savedExpiryTime) {
      const now = new Date().getTime();
      const expiryTime = parseInt(savedExpiryTime, 10);
      this.timeRemaining = Math.max(Math.floor((expiryTime - now) / 1000), 0);
      this.resendAvailable = this.timeRemaining === 0;
    }
  }

  async resendOtp(): Promise<void> {
    
    this.timeRemaining = 60;
    this.saveTimer();
    this.resendAvailable = false;
    this.startTimer();
    this.spinner.show();
    //const token = MemoryStorageService.getItem('customerToken');
    const mobileNo = MemoryStorageService.getItem("AlternateMobileNo")
    const res = await firstValueFrom(this.accountService.GetOTPForAlternativeNumber(mobileNo));
    if(res['error'] == null && res['data'] == 'OTP Sent successfully on given mobile number.'){
      swal.fire("OTP Sent successfully");
      this.spinner.hide();
      return;
    }
    swal.fire("Could not send OTP, Please try again!");
  }

  /* End - OTP Timer */
}
