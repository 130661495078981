import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/core/services/account.service';
import { SessionTimerService } from '../../../core/services/session-timer.service';
import swal from 'sweetalert2';
import { User } from '../../../core/models/user';
import { Subscription, timer } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';

export interface iSubmitOtp {
  mobileNo: string;
  email: string;
  mobileOTP?: number;
  emailOTP?: number;
  iConfirm: boolean;
  userSource: string;
}

@Component({
  selector: 'app-assist-submit-otp',
  templateUrl: './assist-submit-otp.component.html',
  styleUrls: ['./assist-submit-otp.component.scss'],
})
export class AssistSubmitOtpComponent implements OnInit {
  partnerAuthToken: any = '';

  @ViewChild('form', { read: NgForm }) form!: NgForm;
  submitOtp: iSubmitOtp = {
    mobileNo: '',
    email: '',
    iConfirm: false,
    userSource: 'LANDING_PAGE',
  };

  resendAvailable: boolean = false;
  timeRemaining: number = 60;
  private timerSubscription: Subscription | undefined;
  private timerKey: string = 'otpTimer';
  private tokenStr: string = '';
  private maxTenorPlan: any;
  isExpanded = false;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private sessionTimerService: SessionTimerService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.spinner.hide();
    this.submitOtp.mobileNo = MemoryStorageService.getItem('mobile');
    this.submitOtp.email = MemoryStorageService.getItem('email');
    //this.resumeTimer();
    // For Session Hiding Timer
    this.sessionTimerService.SetShowTimer(false);
    this.sessionTimerService.stopTimer();

    //For OTP Timer
    this.loadTimer();
    this.startTimer();

    if (this.route.snapshot.queryParamMap.get('token') != null) {
      this.accountService.GetTempRequestData(this.route.snapshot.queryParamMap.get('token')?.toString().toUpperCase()).subscribe(
        (res) => {
          if (res != null && res['data'] != null) {
            this.submitOtp.mobileNo = res['data'].mobNo;
            this.submitOtp.email = res['data'].email;
            this.tokenStr = res['data'].token;
          }
          else {
            return this.router.navigateByUrl('/assist-journey/sorry');
          }
        });
    };
  }

  submit() {
    this.spinner.show();
    if (this.tokenStr && this.tokenStr != 'undefined' && this.tokenStr != '') {
      this.partnerAuthToken = this.tokenStr;
    }
    else {
      this.partnerAuthToken = MemoryStorageService.getItem('partnerToken');
    }
    this.submitOtp.emailOTP = Number(this.submitOtp.mobileOTP);
    this.accountService
      .submitOtp(this.submitOtp, this.partnerAuthToken)
      .subscribe(
        (res) => {

          let isPlanExist = null;
          const isError = res.hasOwnProperty('error') ? (res['error'] != null ? true : false) : false;
          if (!isError) {
            isPlanExist = res['data'].hasOwnProperty('plan_details');
          }
          //deserialize string response
          if (res['data'] != null && !isPlanExist) {
            MemoryStorageService.setItem('customerToken', res['data']['sessionId']);
            const user: User = { token: res['data']['sessionId'], userName: '', email: '', mobileNumber: '' }
            this.accountService.setCurrentUser(user);
            this.accountService.currentUserSource.pipe(
              take(1) 
            ).subscribe(() => {
              this.accountService.setMoengageUser(user.token);
            });
            const responseMsg = res['data']['response_msg'];
            //this.sessionTimer();
            //if(this.isJsonString(responseMsg))
            if (typeof responseMsg !== 'string') {
              //let details: any = JSON.parse(res['data']['response_msg']);
              let details: any = responseMsg;
              MemoryStorageService.setItem('pan', details['pan']);
              MemoryStorageService.setItem('pincode', details['pincode']);
              MemoryStorageService.setItem('dob', details['dob']);
              MemoryStorageService.setItem('gender', details['gender']);
              MemoryStorageService.setItem('iConfirm', 'true');
              this.spinner.hide();
              return this.router.navigateByUrl('/assist-journey/confirm-details');
            }
            else {
              this.clearMemoryStorageServiceDetails();
              MemoryStorageService.setItem('iConfirm', 'false');
              this.spinner.hide();
              return this.router.navigateByUrl('/assist-journey/confirm-details');
            }
          }
          else if (res['data'] != null && isPlanExist) {

            const plandetails = res['data']['plan_details'];

            if(this.checkPlanEligibility(plandetails)){
              return this.router.navigateByUrl('/assist-journey/assist-underwrite');
            }

            //starting the Session Timer
            this.restartTimer();
            //this.sessionTimer();

            // Setting SessionId to be used on Session Expire Page for updating the apllication Status 
            const user: User = { token: res['data']['sessionId'], userName: '', email: '', mobileNumber: '' }
            MemoryStorageService.setItem('customerToken', res['data']['sessionId']);
            this.accountService.setCurrentUser(user);
            this.accountService.currentUserSource.pipe(
              take(1) 
            ).subscribe(() => {
              this.accountService.setMoengageUser(user.token);
            });
            //////


            //Setting if Available Limit has exhausted 
            // const travelcost = parseInt(MemoryStorageService.getItem('TravelCost'));
            // const availableLimit = parseInt(res['data']['availableLimit']);

            //  MemoryStorageService.setItem('hasLimitExhausted', travelcost > availableLimit ? "true": "false")
            //////

            /*** skip the plan page with selecting max tenure for Consolidator INDCR00173 (now updated for all the landing page users) (TaskId 1073) ***/
            if (MemoryStorageService.getItem("userSource") === 'LANDING_PAGE') {
              MemoryStorageService.setItem('assistTravelPlans', JSON.stringify(plandetails));
              this.maxTenorPlan = res['data'].plan_details.reduce((max, plan) => {
                return (plan.tenor > max.tenor) ? plan : max;
              });
              return this.router.navigateByUrl(
                '/assist-journey/' + this.maxTenorPlan.navigate_url.split('/')[4]
              );
            }
            /********************/

            //check plans
            if (plandetails != null) {
              MemoryStorageService.setItem('assistTravelPlans', JSON.stringify(plandetails));
              this.spinner.hide();
              return this.router.navigateByUrl('/assist-journey/payment-plans', {
                state: {
                  assistTravelPlans: JSON.stringify(plandetails),
                },
              });
            }
          }
          else if (isError && (res['error'].response_summary == "User is declined" || res['error'].response_msg.includes('not eligible'))) {
            swal.fire(res['error']['response_msg']);
            this.spinner.hide();
            return this.router.navigateByUrl('/assist-journey/sorry');
          }
          else if(isError && res['error'].response_summary == "In process" && res['error'].response_msg == "Your loan application is in review by our team of experts.")
          {
            this.spinner.hide();
            return this.router.navigateByUrl('/assist-journey/assist-underwrite');
          }
          else if (isError && (res['error'].response_msg == "Wrong OTP entered thrice. Please generate new OTP." || res['error'].response_msg == "OTP is incorrect, please try again")){
            swal.fire(res['error']['response_msg']);
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
            //MemoryStorageService.setItem('isStartTimer','false');
            //swal.fire(res['error']['response_msg']);
            return this.router.navigateByUrl('/assist-journey/assist-processing');
          }
        },
        (error) => {
          this.spinner.hide();
          return this.router.navigateByUrl('/assist-journey/assist-processing');
          //swal.fire('Unhandled error occured, please try again later.');
        }
      );
  }

  handleLabelKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.submitOtp.iConfirm = !this.submitOtp.iConfirm;
    }
  }

  checkPlanEligibility(plans: any[]) {
    const hasEligiblePlan = plans.some(plan => plan.plan_eligibility);
    return !hasEligiblePlan
  }

  shouldShowValidation(formControl: NgModel) {
    return (
      formControl &&
      formControl.invalid &&
      (formControl.dirty || this.form.submitted)
    );
  }

  toggleReadMore() {
    this.isExpanded = !this.isExpanded;
  }

  private clearMemoryStorageServiceDetails(): void {
    MemoryStorageService.removeItem('pan');
    MemoryStorageService.removeItem('pincode');
    MemoryStorageService.removeItem('dob');
    MemoryStorageService.removeItem('gender');
    MemoryStorageService.removeItem('iConfirm');
  }

  allowOnly(event: KeyboardEvent, type: string): void {
    const char = event.key;
    if (type === 'numbers' && !char.match(/[0-9]/)) {
      event.preventDefault();
    }
    if (type === 'alphabets' && !char.match(/[a-zA-Z]/)) {
      event.preventDefault();
    }
  }

  /* Session Timer */
  sessionTimer(): void {
    this.sessionTimerService.SetShowTimer(true);
    this.sessionTimerService.startTimer();
  }

  restartTimer(): void {
    this.sessionTimerService.SetShowTimer(true);
    this.sessionTimerService.restartTimer();
  }
  /* End - Session Timer */

  /* OTP Timer */
  startTimer(): void {
    const now = new Date().getTime();
    const savedExpiryTime = MemoryStorageService.getItem(this.timerKey);
    if (savedExpiryTime) {
      const expiryTime = parseInt(savedExpiryTime, 10);
      this.timeRemaining = Math.max(Math.floor((expiryTime - now) / 1000), 0);
      this.resendAvailable = this.timeRemaining === 0;
    }
    this.timerSubscription = timer(0, 1000).pipe(
      switchMap(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
          this.saveTimer();
          return [this.timeRemaining];
        } else {
          this.resendAvailable = true;
          MemoryStorageService.removeItem(this.timerKey);
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          return [];
        }
      })
    ).subscribe();
  }

  saveTimer(): void {
    const expiryTime = new Date().getTime() + (this.timeRemaining * 1000);
    MemoryStorageService.setItem(this.timerKey, expiryTime.toString());
  }

  loadTimer(): void {
    const savedExpiryTime = MemoryStorageService.getItem(this.timerKey);
    if (savedExpiryTime) {
      const now = new Date().getTime();
      const expiryTime = parseInt(savedExpiryTime, 10);
      this.timeRemaining = Math.max(Math.floor((expiryTime - now) / 1000), 0);
      this.resendAvailable = this.timeRemaining === 0;
    }
  }

  resendOtp(): void {
    this.timeRemaining = 60;
    this.saveTimer();
    this.resendAvailable = false;
    this.startTimer();
    this.accountService.resendOTPMobile(this.submitOtp.mobileNo, MemoryStorageService.getItem('partnerToken')).subscribe(
      (res) => {
        if (res['data'] != null && res['data']['response_summary'] != null) {
          swal.fire(res['data']['response_summary']);
        }
        else if (res['error'] != null && res['error']['response_summary'] == "Max 5 OTP can be send on 15min interval") {
          MemoryStorageService.removeItem(this.timerKey);
          if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
          }
          this.timeRemaining = 60 * 15;
          this.saveTimer();
          this.resendAvailable = false;
          this.startTimer();
          swal.fire(res['error']['response_summary']);
        }
        else {
          swal.fire(res['error']['response_summary']);
        }
      },
      (error) => {
        this.spinner.hide();
        //swal.fire('Unhandled error occured, please try again later.');
      }
    );
  }

  /* End - OTP Timer */

}
