<div class="row justify-content-center mt-3 d-flex flex-lg-row-reverse">
    <div class="col-lg-6 col-12">
        <div class="row justify-content-center mt-lg-5">
            <div class="col-8 blue_box p-4 text-center">
                <h3 class="heading">Verify your contact details</h3>
            </div>
        </div>
        <div class="row m-0 justify-content-center">
            <form class="container-fluid page mt-60" ngForm #form="ngForm" (ngSubmit)="submit()" autocomplete="off">
                <div class="col-12 white_box p-4">
                    <p>Enter the OTP sent on your mobile 
                        <strong>{{submitOtp.mobileNo}}</strong> 
                        <!-- and on your email starting with
                        <strong>{{submitOtp.email.substring(0,5)}}</strong> -->
                    </p>
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <div class="form-group">
                                <label for="mobileOtp" class="control-label">Mobile OTP</label>
                                <input type="text" class="form-control" placeholder="Enter mobile OTP" [(ngModel)]="submitOtp.mobileOTP" required #mobileOTP="ngModel" maxlength="4" pattern="\d*" name="mobileOTP" (keypress)="allowOnly($event,'numbers')" tabindex="1">
                                <div class="timer-container">
                                    <span *ngIf="!resendAvailable">{{ timeRemaining }}s</span>
                                    <button *ngIf="resendAvailable" class="btn btn-link btn-response" (click)="resendOtp()" tabindex="2">
                                        <i class="fa fa-paper-plane" aria-hidden="true" style="color: #044b5f;font-size:1em;"></i>
                                        <svg height="20" width="55" style="font-weight: unset;">
                                            <text x="5" y="13" fill="blue" font-size="1em" style="font-weight: 500;">Resend</text>
                                       </svg>
                                    </button>
                                </div>
                                <!-- <div class="row mt-2 text-right buttonDiv" *ngIf="false">
                                    <div class="col-12">
                                        <button class="btn btn-sm interLink" type="button" (click)="resendOtp()">Resend OTP</button>
                                    </div>
                                </div> -->
                                <div *ngIf="mobileOTP.invalid && (mobileOTP.dirty || mobileOTP.touched)" class="error">
                                    <div *ngIf="mobileOTP.errors.required">
                                        OTP is required.
                                    </div>
                                    <div *ngIf="mobileOTP.errors.pattern">
                                        OTP must be numeric.
                                    </div>
                                    <div *ngIf="mobileOTP.errors.maxlength">
                                        OTP must be 4 digits.
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <p>{{ triesLeft }} tries left</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-10">
                            <div class="boxes">
                                <input type="checkbox" id="box1" class="form-check-input" [(ngModel)]="submitOtp.iConfirm" name="checkbox" #iConfirm="ngModel" required >
                                <label for="box1" tabindex="3" (keydown)="handleLabelKeydown($event)">Yes, I hereby appoint
                                    iTraveleo Private Limited ("Mihuru")<label *ngIf="!isExpanded" id="dots">...</label>
                                    <span class="label1" *ngIf="isExpanded" id="more"> as an authorized representative to receive my
                                    Credit information from Equifax Credit Information Services Private Limited
                                    ("Equifax") for the purpose of Credit Assessment for this application and also
                                    retrieve my KYC information from the Central KYC records for the same. Notwithstanding 
                                    the above, to the extent permitted under the law, I agree and acknowledge that a lender 
                                    identified by Mihuru can independently access my credit information in connection with  
                                    its Own Credit Assessment. I declare that all the information provided here is correct.</span>
                                    <a class="adecor" (click)="toggleReadMore(); $event.stopPropagation(); $event.preventDefault();">{{ isExpanded ? 'Read less' : 'Read more' }}</a></label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-2 text-center buttonDiv">
                        <div class="col-12">
                            <button type="submit" class="btn btn-warning mt-3"
                                [disabled]="(mobileOTP.invalid || !submitOtp.iConfirm)" tabindex="4"> Verify
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="col-lg-5 col-12">
        <app-assist-left></app-assist-left>
    </div>
    <ngx-spinner type="ball-spin-clockwise" size="medium"></ngx-spinner>
</div>