import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { MemoryStorageService } from 'src/app/core/services/memory-storage.service';
import swal from 'sweetalert2';

export interface DocumentFile {
  professionIdtype: string,
  file: string,
  type: string
}

@Component({
  selector: 'app-assist-utility',
  templateUrl: './assist-utility.component.html',
  styleUrl: './assist-utility.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }), 
        animate('500ms 0s ease-in', style({ opacity: 1 })) 
      ])
    ]),
    
  ]
})


export class AssistUtilityComponent {
  @ViewChild('utilitydoc') utilitydoc: ElementRef;
  residenceTypes = [ 'Owned', 'Rented'];
  documentOwnedTypes = [ 'Landline Bill', 'Broadband Bill', 'Electricity Bill', 'Gas Pipeline Bill', 'Water Bill', 'LPG Passbook' ];
  documentRentedTypes = [ 'Landline Bill', 'Broadband Bill', 'LPG Passbook' ];
  model: FormGroup;
  UploaderLabelName: string = '';
  fileSelected: boolean =  false;
  filesData: DocumentFile[] = [
    { professionIdtype: '', file: '', type: '' }, 
  ]

  constructor(private fb: FormBuilder, private accountService: AccountService, private spinner: NgxSpinnerService, private router: Router){
    this.model = this.fb.group({
      residenceType: 'Please residence type',
      documentType: 'Please document type'
    });

    this.model.get('residenceType')?.valueChanges.subscribe((value) => {
      this.fileSelected = false;
      this.model.get('documentType').setValue('Please document type'); 
    });

    this.model.get('documentType')?.valueChanges.subscribe((value) => {
      this.fileSelected = false;
      if (this.utilitydoc) {
        this.utilitydoc.nativeElement.value = '';
      }
    });

  }

  private getFileExtension(fileName: string): string {
    const fileParts = fileName.split('.');
    return fileParts.length > 1 ? fileParts.pop()! : '';
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.fileSelected = true;
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileBase64 = reader.result as string;
        this.filesData[0] = { professionIdtype: this.model.get('documentType')?.value, type: this.getFileExtension(file.name), file: fileBase64.split('base64,')[1] }
      };
      reader.readAsDataURL(file);
    }

  }


  async submit(){
    let objupload = {
      "occupationType": MemoryStorageService.getItem("professionType"),
      "uploadFiles": this.filesData
    };
    await this.accountService.uploadBusinessDocuments(objupload).subscribe(res => {
      if(res['data']){
        this.spinner.hide();
        lastValueFrom(this.accountService.UpdateApplicationReason(MemoryStorageService.getItem("currentStatus"), 'Utility Bill Uploaded')).then( res => {
          return this.router.navigateByUrl('/assist-journey/assist-income-proof');
        });
        
      }
      else {
        this.spinner.hide();
        swal.fire({
          text: "Unable to Upload",
          confirmButtonText: 'OK'
        }).then((result) => {
            return;
        });  
        }
    });
  }
}
