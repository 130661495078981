import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AutocompleteOffDirective } from 'src/app/core/directive/autocomplete-off.directive';
import { CdnImageDirective } from 'src/app/core/directive/cdn-image.directive';
import { ClickOutsideDirective } from 'src/app/core/directive/click-outside.directive';
import { MobileNumberPatternDirective } from 'src/app/core/directive/mobile-number-pattern.directive';
import { NumbersOnlyDirective } from 'src/app/core/directive/numbers-only.directive';
import { EncryptPhoneEmailPipe } from 'src/app/core/pipes/encrypt-phone-email.pipe';
import { TitleCasePipe } from 'src/app/core/pipes/title-case.pipe';
import { AadhaarVerificationComponent } from './aadhaar-verification/aadhaar-verification.component';
import { AddressProofComponent } from './address-proof/address-proof.component';
import { AssistConfirmPersonalDetailsComponent } from './assist-confirm-personal-details/assist-confirm-personal-details.component';
import { AssistEmiTableComponent } from './assist-emi-table/assist-emi-table.component';
import { AssistJourneyRoutingModule } from './assist-journey-routing.module';
import { AssistLeftComponent } from './assist-left/assist-left.component';
import { AssistLoanAgreementComponent } from './assist-loan-agreement/assist-loan-agreement.component';
import { AssistPaymentPlanComponent } from './assist-payment-plan/assist-payment-plan.component';
import { AssistPersonalDetailsComponent } from './assist-personal-details/assist-personal-details.component';
import { AssistProcessingComponent } from './assist-processing/assist-processing.component';
import { AssistRightComponent } from './assist-right/assist-right.component';
import { AssistShowPaymentPlansComponent } from './assist-show-payment-plans/assist-show-payment-plans.component';
import { AssistSignUpComponent } from './assist-sign-up/assist-sign-up.component';
import { AssistSubmitOtpComponent } from './assist-submit-otp/assist-submit-otp.component';
import { AssistSuccessComponent } from './assist-success/assist-success.component';
import { AssistTeamCallbackComponent } from './assist-team-callback/assist-team-callback.component';
import { AssistUnderwriteComponent } from './assist-underwrite/assist-underwrite.component';
import { BankStatementComponent } from './bank-statement/bank-statement.component';
import { DocumentsUploadQRCodeComponent } from './documents-upload-qr-code/documents-upload-qr-code.component';
import { IdentityProofComponent } from './identity-proof/identity-proof.component';
import { NachMandateComponent } from './nach-mandate/nach-mandate.component';
import { NachPopComponent } from './nach-pop/nach-pop.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { PleaseWaitNachComponent } from './please-wait-nach/please-wait-nach.component';
import { ProfessionalDetailsComponent } from './professional-details/professional-details.component';
import { SalaryUploadComponent } from './salary-upload/salary-upload.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { SessiontimeoutComponent } from './sessiontimeout/sessiontimeout.component';
import { SorryComponent } from './sorry/sorry.component';
import { UploadAndPreviewComponent } from './upload-and-preview/upload-and-preview.component';
import { AssistUtilityComponent } from './assist-utility/assist-utility.component';
import { AssistIncomeProofComponent } from './assist-income-proof/assist-income-proof.component';

@NgModule({
  declarations: [
    UploadAndPreviewComponent,
    SessionExpiredComponent,
    AssistLeftComponent,
    SalaryUploadComponent,
    AssistUnderwriteComponent,
    AssistRightComponent,
    BankStatementComponent,
    DocumentsUploadQRCodeComponent,
    AddressProofComponent,
    IdentityProofComponent,
    NachPopComponent,
    NachMandateComponent,
    PaymentGatewayComponent,
    PleaseWaitNachComponent,
    ProfessionalDetailsComponent,
    AadhaarVerificationComponent,
    AssistConfirmPersonalDetailsComponent,
    AssistEmiTableComponent,
    AssistLoanAgreementComponent,
    AssistPaymentPlanComponent,
    AssistPersonalDetailsComponent,
    AssistProcessingComponent,
    AssistShowPaymentPlansComponent,
    AssistSignUpComponent,
    AssistSubmitOtpComponent,
    AssistSuccessComponent,
    AssistTeamCallbackComponent,
    AssistUtilityComponent,
    AssistIncomeProofComponent,
    SorryComponent,
    TitleCasePipe,
    CdnImageDirective,
    ClickOutsideDirective,
    AutocompleteOffDirective,
    NumbersOnlyDirective,
    MobileNumberPatternDirective,
    EncryptPhoneEmailPipe,
    SessiontimeoutComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    AssistJourneyRoutingModule,
  ],
  exports: [
    CdnImageDirective,
    ClickOutsideDirective,
    AutocompleteOffDirective,
    NumbersOnlyDirective,
    MobileNumberPatternDirective,
    EncryptPhoneEmailPipe,
    SessiontimeoutComponent
  ]
})
export class AssistJourneyModule { }
